.dropdown {
  position: relative;
  flex: 0 0 auto;
  font-family: $fonts-paragraph;
  font-size: 12px;
  margin: 4px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover:not(.disabled) {
    .dropdown-menu {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &.text-align-left {
    text-align: left;
  }

  .dropdown-toggle {
    font-size: 12px;
    font-weight: bold;
    color: rgba(0, 0, 0, .5);
    font-family: $fonts-paragraph;
    width: 100%;
    height: 100%;
    text-align: left;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    &.large-font {
      font-size: 18px;
      font-weight: bold;
    }

    fa-icon {
      margin-left: 12px;
    }
  }

  .dropdown-menu,
  .dropdown-item {
    width: 100%;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    z-index: 1;
    left: auto;
    right: 0;
    width: 150px;
    padding: 4px 0;

    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .1);

    opacity: 0;
    pointer-events: none;
    transition: .2s opacity;

    max-height: 60vh;
    overflow-y: auto;

    .dropdown-item {
      display: block;
      padding: 4px 16px;
      border-bottom: 0;
      text-align: right;
      color: rgba(0, 0, 0, .5);

      .icon-check-mini {
        position: absolute;
        top: 50%;
        margin-top: -4px;
        left: 12px;
        display: none;
      }

      &.large-font {
        font-size: 18px;
      }

      &.text-align-left {
        text-align: left;
      }

      &:hover,
      &.active {
        background-color: #f9f9f9;
        color: #000;
        font-weight: bold;
      }

      &.active {
        .icon-check-mini {
          display: block;
        }
      }

      .heading {
        color: #000;
        margin-bottom: 8px;
      }

      &:hover {
        .heading {
          font-weight: bold;
        }
      }

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}
