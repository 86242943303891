@import 'settings/vars';

// extracted and generalized from the key edit door detail page
:not(.card-page) > .card {
  border: 1px solid rgba(0, 0, 0, .1);
  border-radius: 10px;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, .5);

  .vertical {
    flex-direction: column;
    align-items: start;
  }

  .latch-checkbox {
    margin-right: 20px;
  }

  .latch-icon-close {
    cursor: pointer;
  }

  .card-handle {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 15px 0;
    width: 100%;
    text-align: left;

    &.space-bt {
      justify-content: space-between;
    }

    &.vertical {
      flex-direction: column;
      align-items: start;
    }

    .card-title {
      flex: 1;
      line-height: 20px;
      font-size: 16px;
      color: rgba(0, 0, 0, .75);
    }

    .card-subtitle {
      font-size: 12px;
      line-height: 20px;
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: #e1e1e1;
    margin: 16px 0;

    &:first-child {
      margin-top: 0;
    }
  }

  .card-details {
    margin: 0 10px;
    flex: 1;
    display: flex;
    flex-direction: column;

    .card-detail-row {
      display: flex;
      flex-direction: row;
      margin-bottom: 16px;

      .icon {
        display: inline-flex;
        width: 32px;
        vertical-align: text-top;
      }

      .text {
        color: rgba(0, 0, 0, .5);
        margin: 0 10px;
        vertical-align: center;
        flex: 1;

        &.text-dark {
          color: #000;
        }

        .text {
          margin-left: 0;
          margin-top: 16px;
        }

        .title {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 4px;
        }

        .text-divider {
          text-align: center;
          font-size: 12px;
          width: 100%;
          display: inline-flex;
          justify-content: space-between;
          align-items: center;
          color: rgba(0, 0, 0, .35);
          height: 40px;

          hr {
            width: 100px;
            height: 1px;
            background-color: $color-gray-4;
            border: 0;
          }
        }

        .description {
          font-size: 12px;
          margin-bottom: 4px;
        }
      }
    }
  }

  &.selected {
    background-color: #f7f7f7;

    .door-name {
      font-weight: bold;
      color: #000;
    }
  }

  &.editable {
    color: #000;
  }
}

.faded {
  opacity: .5;
}
