latch-card {
  .field-list {
    .cell {
      margin: 0 $horizontal-padding;
    }
  }
}

.field-list {
  .section {
    border-bottom: 1px solid rgba(0, 0, 0, .05);

    &:last-child {
      border-bottom: 0;
    }
  }

  .cell {
    padding: $vertical-padding 0;
    border-bottom: 1px solid rgba(0, 0, 0, .05);

    &:last-child {
      border-bottom: 0;
    }

    &.action {
      padding: $vertical-padding;
    }

    .cell-heading {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 8px;

      font-family: $fonts-primary;
      font-size: 13px;
      font-weight: normal;
      color: rgba(0, 0, 0, .5);

      &.spaced {
        margin-bottom: $vertical-padding;
      }
    }

    .cell-body {
      font-size: 24px;
      line-height: 36px;
      color: rgba(0, 0, 0, .5);

      .latch-button:first-child {
        margin-top: 12px;
      }

      .cell-body-text {
        font-family: $fonts-primary;
        font-weight: normal;
        font-size: 12px;
      }

      .cell-value {
        width: 100%;
        font-size: 24px;
        line-height: 36px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &.placeholder {
          color: rgba(0, 0, 0, .25);
        }

        .latch-icon-chevron-right {
          opacity: .15;
        }
      }

      .latch-form-control {
        border: 0;
        padding: 0;
        font-size: 24px;
        line-height: 36px;
        height: 36px;

        &::placeholder {
          color: rgba(0, 0, 0, .25);
        }
      }
    }
  }
}

.has-error {
  color: $color-danger;

  .help-block {
    color: $color-danger;
  }

  .latch-form-control {
    border-color: $color-danger;
    border-top-color: $color-gray-4;
  }

  .latch-form-control-feedback {
    color: $color-danger;
  }
}
