// Plain <button>s don't get styling inherently, so reset rules to make them essentially a <div>
// Most of these rules are just to override v1 styles, and should be able to be removed at some point
@mixin button-base {
  padding: 0;

  border: 0;
  border-radius: 0;

  font-family: $fonts-paragraph;
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  text-align: center;

  background-color: transparent;

  cursor: pointer;
  outline: none;

  &:hover,
  &:focus,
  &:active {
    opacity: .75;
  }

  &[disabled],
  &.disabled {
    cursor: default;
    opacity: .25;
  }

  // button centered is useful for adapting button styling for links
  &.button-centered {
    margin: 20px auto;
    display: block;
    text-align: center;
  }

  &.button-fat {
    padding: 20px 12px;
    border-radius: 30px;
  }
}

@mixin button-text {
  font-family: $fonts-primary;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  line-height: 1;
}

@mixin button-pill {
  @include button-text;

  width: 100%;
  padding: 16px 16px 12px;
  border-radius: 22px;

  font-size: 14px;
}

button {
  @include button-base;
}

.button-default {
  @include button-base;
  @include button-pill;

  background-color: #fff;
  border: 1px solid $color-blue;
  color: $color-blue;

  &.danger {
    border-color: $color-red;
    color: $color-red;
  }

  &.dark {
    border: 1px solid $color-gray-8;
    color: $color-gray-8;
  }
}

.button-primary {
  @include button-base;
  @include button-pill;

  background-color: $color-blue;
  color: #fff;

  &:hover,
  &:focus,
  &:active {
    opacity: 1;
    color: rgba(255, 255, 255, .75);
  }

  &[disabled],
  &.disabled {
    opacity: 1;
    color: rgba(255, 255, 255, .25);
  }

  &.dark {
    background: #000;
    border-radius: 100px;
    font-size: 14px;
    letter-spacing: 2.55px;
    text-align: center;
    line-height: 14px;
  }
}
