// header-bar.scss
//
// nav-bar without the navigation elements

.header-bar {
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, .1);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 3px;

    padding: 22px 20px 18px;
    margin: 0;
  }
}
