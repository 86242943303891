// utility.scss
//
// Reusable widget styles that would have their own component if they required any interaction logic.

@import '../../../../shared/assets/styles/modules/utility';

.no-scroll {
  overflow: hidden;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $color-gray-4;
}

// Our font aligns its characters all the way to the top of a line, which can make its vertical alignment awkward.
// This helper class pushes the text down a little bit to even things out. It could also be used to cancel the effect
// out in environments where a backup font is used (e.g. IE10/11).
.text-line {
  display: inline-block;
  margin-top: .2em;
  margin-bottom: -.2em;
}
