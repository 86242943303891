.prompt {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, .75);

  .content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    border-top: 1px solid #e1e1e1;
  }

  .message {
    margin: $vertical-padding $horizontal-padding 60px;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, .75);

    h3 {
      font-size: 16px;
      font-weight: bold;
      line-height: 20px;
      margin-bottom: $vertical-padding;
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: #e1e1e1;
    margin: 16px 0;
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid #e1e1e1;

    .latch-button-text {
      width: auto;
      padding: $vertical-padding $horizontal-padding;

      &:first-child {
        font-weight: normal;
      }

      &:only-child {
        width: 100%;
      }
    }
  }
}
