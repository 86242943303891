@import 'settings/vars';

// data-table.scss
//
// Latch-themed data table styles.

.data-table {
  border-collapse: collapse;
  width: 100%;
  text-align: left;
  table-layout: fixed;
  border: 0;
  border-bottom: 1px solid $color-gray-4;
  background-color: #fff;

  font-family: $fonts-paragraph;
  font-size: 12px;

  th,
  td {
    // Prefer longer lines to wrapping text in order to keep all rows same height.
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    padding: $table-cell-padding-vertical $table-cell-padding-horizontal;
  }

  thead {
    th {
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 3px;
      border-bottom: 1px solid rgba(0, 0, 0, .25);
      text-align: left;
      background-color: #fff;
    }
  }

  tbody {
    tr {
      &:hover {
        background: rgba(0, 0, 0, .02);

        td {
          background: transparent;
        }
      }

      &.active {
        background: $color-gray-2;
      }

      // Empty rows get no hover state
      &.empty {
        text-align: center;

        &:hover {
          background: none;
        }
      }

      td {
        border-bottom: 1px solid rgba(0, 0, 0, .25);

        // For table cells whose content contains a link, move padding from the cell to the link to
        // make it easier to click on.
        & > a {
          display: block;
          padding: $table-cell-padding-vertical $table-cell-padding-horizontal;
          margin: $table-cell-padding-vertical * -1 $table-cell-padding-horizontal * -1;
        }
      }
    }
  }
}

.latch-data-table {
  width: 100%;
  border: 0;
  border-spacing: 0;
  position: relative;
  padding-bottom: 180px;

  thead {
    text-align: left;

    tr {
      height: 50px;
      color: $color-black;
      font-weight: bold;
      font-size: 12px;
    }

    th {
      background-color: $table-header-background-color;
      position: sticky;
      top: 0;
      // so that rows don't scroll over sticky header
      //z-index: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      color: $table-header-text-color;
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 9px;

      &.lighter {
        font-weight: 400;
      }
    }

    th:first-child {
      padding-left: 30px;
    }

    th:last-child {
      padding-right: 30px;
    }
  }

  tbody {
    tr {
      vertical-align: top;
      cursor: pointer;
      // for absolute position in ::after to work
      transform: scale(1);

      &::after {
        content: '';
        width: calc(100% - 60px);
        height: 1px;
        position: absolute;
        background-color: $table-body-background-color;
        left: 30px;
        bottom: 0;
      }

      &.not-selected {
        opacity: .4;
      }

      td {
        height: 90px;
        padding-top: 25px;
      }

      td:first-child {
        padding-left: 30px;
      }

      td:last-child {
        padding-right: 30px;
      }
    }
  }
}

.latch-table-column-name {
  display: flex;
  flex-direction: row;
  align-items: center;

  .cell-thumbnail {
    margin-right: 12px;
  }

  .cell-name {
    display: inline-flex;
    flex-direction: column;
  }

  .name-primary {
    color: $color-black;
    font-family: $fonts-primary;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -.2px;
    line-height: 1.2;
    margin-bottom: 2px;
  }

  .name-secondary {
    color: $color-gray-50;
    font-family: $fonts-primary;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 1.2;
  }
}
