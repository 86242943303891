@mixin icon($url, $width, $height) {
  width: $width;
  height: $height;
  background-image: url($url);
  background-size: $width $height;
  background-position: center center;
  background-repeat: no-repeat;
}

@mixin icon-size-override($width, $height) {
  width: $width;
  height: $height;
  background-size: $width $height;
  background-position: center center;
}

.icon-warning-circle {
  @include icon('../../images/fail.svg', 30px, 30px);
}

.icon-warning-circle-gray {
  @include icon('../../images/warning-circle-gray.svg', 32px, 32px);
}

.icon-error-circle-black {
  @include icon('../../images/error-circle-black.svg', 32px, 32px);
}

.icon-error-circle {
  @include icon('../../images/error-circle.svg', 32px, 32px);
}

.icon-update-task {
  @include icon('../../images/update-task.svg', 20px, 20px);
}

.icon-chevron-down {
  @include icon('../../images/latch-chevron-down.svg', 16px, 10px);
}

.icon-check-mini {
  @include icon('../../images/check-mini.svg', 8px, 8px);
}

.icon-check-blue {
  @include icon('../../../../shared/assets/images/check-blue.svg', 10px, 10px);
}

.icon-elevator-key {
  @include icon('../../images/elevator-key.svg', 28px, 28px);
}

.icon-concierge {
  @include icon('../../images/concierge.svg', 54px, 54px);
}

.icon-bookings {
  @include icon('../../images/bookings.svg', 16px, 16px);
}

.icon-integrations {
  @include icon('../../images/integrations.svg', 16px, 16px);
}

.icon-rent-pay {
  @include icon('../../images/rent-pay.svg', 16px, 16px);
}

.icon-resources {
  @include icon('../../images/resources.svg', 16px, 16px);
}

.icon-line-chart-down {
  @include icon('../../images/line-chart-down.svg', 95px, 40px);
}

.icon-line-chart-horizontal {
  @include icon('../../images/line-chart-horizontal.svg', 95px, 70px);
}

.icon-line-chart-up {
  @include icon('../../images/line-chart-up.svg', 95px, 40px);
}

.icon-door-key {
  @include icon('../../images/door-key.svg', 28px, 28px);
}

.icon-logo {
  @include icon('../../../../shared/assets/images/latch-logo.svg', 85px, 18px);
}

.icon-delivery-key {
  @include icon('../../images/deliveries-key.svg', 28px, 28px);
}

.icon-mfa-enabled {
  @include icon('../../images/mfa-enabled.svg', 24px, 24px);
}

.icon-mfa-disabled {
  @include icon('../../images/mfa-disabled.svg', 24px, 24px);
}

.icon-mfa-required {
  @include icon('../../images/mfa-required.svg', 24px, 24px);
}

.icon-mfa-not-required {
  @include icon('../../images/mfa-not-required.svg', 24px, 24px);
}

.icon-info-circle {
  @include icon('../../../../shared/assets/images/information-circle.svg', 20px, 20px);
}

.icon-hamburger {
  @include icon('../../../../shared/assets/images/hamburger.svg', 20px, 20px);
}

.icon-circle-plus {
  @include icon('../../../../shared/assets/images/circle-plus.svg', 42px, 42px);
}

.icon-circle-minus {
  @include icon('../../../../shared/assets/images/circle-minus.svg', 42px, 42px);
}

.icon-circle-monday {
  @include icon('../../../../shared/assets/images/circle-m.svg', 44px, 44px);
}

.icon-circle-tuesday {
  @include icon('../../../../shared/assets/images/circle-t.svg', 44px, 44px);
}

.icon-circle-wednesday {
  @include icon('../../../../shared/assets/images/circle-w.svg', 44px, 44px);
}

.icon-circle-thursday {
  @include icon('../../../../shared/assets/images/circle-r.svg', 44px, 44px);
}

.icon-circle-friday {
  @include icon('../../../../shared/assets/images/circle-f.svg', 44px, 44px);
}

.icon-circle-saturday {
  @include icon('../../../../shared/assets/images/circle-s.svg', 44px, 44px);
}

.icon-circle-sunday {
  @include icon('../../../../shared/assets/images/circle-s.svg', 44px, 44px);
}

.icon-filter {
  @include icon('../../images/latch-filter.svg', 22px, 20px);
}

.icon-burst {
  @include icon('../../../../shared/assets/images/icon_burst.svg', 18px, 16px);
}

.icon-blue-arrow-left {
  @include icon('../../../../shared/assets/images/arrow-left-blue.svg', 6px, 10px);
}

.icon-blue-arrow-right {
  @include icon('../../../../shared/assets/images/arrow-right-blue.svg', 6px, 10px);
}

.icon-thumbs-down-empty {
  @include icon('../../../../shared/assets/images/thumbs-down-empty.svg', 18px, 18px);
}

.icon-thumbs-down-selected {
  @include icon('../../../../shared/assets/images/thumbs-down-selected.svg', 18px, 18px);
}

.icon-thumbs-up-empty {
  @include icon('../../../../shared/assets/images/thumbs-up-empty.svg', 18px, 18px);
}

.icon-thumbs-up-selected {
  @include icon('../../../../shared/assets/images/thumbs-up-selected.svg', 18px, 18px);
}

.icon-external-link-blue {
  @include icon('../../images/external-link-blue.svg', 14px, 14px);
}

.icon-external-link-white {
  @include icon('../../images/external-link-white.svg', 14px, 14px);
}

.icon-logo-payments {
  @include icon('../../../../shared/assets/images/latch-logo.svg', 133px, 30px);
  display: block;
}

.icon-plus {
  @include icon('../../images/icon_plus.svg', 13px, 13px);
  display: block;
}

.small-icon-plus {
  @include icon('../../images/icon_plus.svg', 10px, 10px);
  display: block;
}

.logo-stripe {
  @include icon('../../images/logo_stripe.svg', 70px, 29px);
  display: block;
}

.icon-info-circle-grey {
  @include icon('../../../../shared/assets/images/information-circle-grey.svg', 18px, 18px);
}

.icon-more-partners {
  @include icon('../../images/more-partners.svg', 16px, 16px);
}

.icon-empty {
  @include icon('../../images/empty.svg', 180px, 180px);
}

.icon-avatar-file {
  @include icon('../../images/avatar-file.png', 40px, 40px);
}
