.csv-import-box {
  width: 100%;
  height: 56px;
  background-color: rgba(0, 0, 0, .02);
  display: flex;
  align-items: stretch;

  .latch-button-link {
    flex: 1 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .import-selection {
    flex: 1 0 auto;
    padding: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .import-summary {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .latch-icon-confirm {
    margin-right: 12px;
  }

  .latch-icon-close {
    opacity: .25;

    &:hover {
      opacity: 1;
    }
  }
}
