// main.scss
//
// Entry point for our global CSS. Don't define any CSS here, just use it to manage import order (and therefore, rule precedence).

// http://stackoverflow.com/a/39535907
@import '~normalize.css';
@import 'mapbox-gl/dist/mapbox-gl.css';

// Importing vars here will make them available for anything that follows (no need to import again).
@import 'settings/vars';

// When a set of style rules become a re-usable pattern, but are essentially useless on their own,
// create a mixin instead of a helper class to avoid cluttering HTML.
@import 'mixins/nav-bar';
@import 'mixins/modal';

// Essentially CSS "components" - but use the name "modules" to avoid confusion with Angular components.
@import 'modules/utility';
@import 'modules/icons';
@import 'modules/buttons';
@import 'modules/layout';
@import 'modules/cell-list';
@import 'modules/field-list';
@import 'modules/prompt';
@import 'modules/data-table';
@import 'modules/header-bar';
@import 'modules/dropdown';
@import 'modules/card';
@import 'modules/card-page';
@import 'modules/progress-page';
@import 'modules/spinner-ring';
@import 'modules/csv-import';
@import 'modules/badge';
