@import 'settings/vars';
@import '/node_modules/@latch/latch-web/styles/variables';

$page-margin: 40px;

$min-height: calc(100vh - #{$header-height} - #{2 * $page-margin});

.page.page.page {
  flex: 1 0 auto;
  position: relative;
  width: 100%;
  max-width: $breakpoint-sm;
  min-height: $min-height;
  margin: $page-margin auto;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
  display: flex;
  flex-direction: column;

  &.with-fixed-content {
    .latch-card-content {
      position: relative;
    }

    .latch-card-content-fixed {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: auto;
    }

    .latch-card-footer {
      border-top: 1px solid rgba(0, 0, 0, .05);
    }
  }

  .latch-card-actions {
    margin-bottom: $vertical-padding;
  }

  .latch-card-content {
    padding: 0;
    min-height: 0;

    latch-labeled-list-item {
      margin: 0 30px;
    }
  }

  .latch-card-footer {
    .button-default {
      display: inline-block;
    }
  }

  &.fixed {
    height: calc(100vh - #{$header-height} - (2 * #{$page-margin}));
    min-height: 400px;

    .latch-card-content {
      overflow: auto;
    }

    .latch-card-footer {
      border-top: 1px solid rgba(0, 0, 0, .05);
    }
  }
}

.latch-dialog {
  .page.page.page {
    margin: 0;
    max-height: 100vh;
  }

  .latch-card-content {
    overflow-y: auto;
  }

  .cell-list {
    padding: 0 30px;
  }
}

.latch-card-header-offset {
  margin: 0 -#{$horizontal-padding} -#{$vertical-padding};
}

.latch-tabs-wrapper {
  display: block;
  margin-top: $vertical-padding;
  border-bottom: 0;
}

.latch-tab-group {
  display: block;
}

.latch-card-title {
  .label {
    display: inline-block;
    background: $color-gray-5;
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 1.8;
    padding: 0 6px;
    border-radius: 4px;
    vertical-align: middle;
    user-select: none;
  }
}

.filter-dropdown {
  font-size: 12px;
  margin-right: 10px;

  .dropdown-section {
    font-size: 16px;
    color: #000;
  }
}

.page-description {
  margin-top: $vertical-padding;
  margin-bottom: $vertical-padding;
  font-family: $fonts-primary;
  font-size: 14px;
  color: $color-black-transparent;
}

.page-body {
  flex: 1 1 auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.page-toolbar {
  padding: $vertical-padding $horizontal-padding 5px;
  margin-top: $vertical-padding;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $page-toolbar-background-color;
  color: $page-toolbar-text-color;
  box-shadow: inset 0 10px 6px -12px rgba(0, 0, 0, .3);
  height: 40px;
  box-sizing: border-box;
}

.page-data-count {
  font-size: 12px;
  letter-spacing: 1px;
}

.page-data-sort {
  font-size: 12px;

  .label {
    font-weight: bold;
  }

  .select {
    outline: none;
    border: unset;
  }
}

.page-footer {
  flex: 0 0 auto;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  .button-primary,
  .button-default,
  .button-danger {
    margin: $vertical-padding $horizontal-padding;
  }

  .latch-button-outline {
    margin: $vertical-padding $horizontal-padding;
  }
}

.focus-mode {
  .page {
    &.fixed {
      height: calc(100vh - (2 * #{$page-margin}));
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .page {
    margin: 0;
    box-shadow: none;
    min-height: calc(100vh - #{$header-height-mobile} - #{$ios-safari-buffer-height});

    &.fixed {
      height: calc(100vh - #{$header-height-mobile} - #{$ios-safari-buffer-height});
    }
  }

  .focus-mode {
    .page {
      &.fixed {
        height: calc(100vh - #{$ios-safari-buffer-height});
      }
    }
  }

  .latch-card {
    box-shadow: none !important;
  }
}

.is-ie {
  .page {
    height: $min-height;

    .latch-card-content {
      overflow-y: auto;
      display: block;
    }
  }
}

@media (max-height: $height-sm) {
  .page {

    &,
    &.fixed {
      overflow: auto;
      height: auto;

      .latch-card-content {
        min-height: auto;
        overflow: unset;
      }
    }
  }
}
