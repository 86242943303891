@import 'settings/vars';

.latch-badge {
  display: inline-block;
  line-height: 20px;
  text-align: center;
  color: #fff;
  margin-left: 5px;
  vertical-align: middle;
  border-radius: 10px;
  padding: 1px 12px;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: .2px;

  &.blue {
    background: $color-blue;
  }

  &.gray {
    background: $color-gray-badge;
  }

  &.red {
    background: #f00;
  }

  &.dark {
    background: #000;
  }

  &.round {
    height: 24px;
    line-height: 24px;
    width: 24px;
    border-radius: 50%;
    text-align: center;
    padding: 0;
    margin: 0;
  }
}
