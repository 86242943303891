@import 'settings/vars';

.progress-page {

  .page-body {
    padding: 0 20px;

    h1 {
      font-size: 24px;
      font-weight: normal;
      line-height: 32px;
      margin-bottom: 16px;
    }
  }

  .progress-bar {
    position: relative;

    display: block;

    width: 100%;
    height: 4px;
    margin-bottom: 24px;

    background-color: $color-gray-3;

    transition: .3s opacity;

    &.invisible {
      opacity: 0;
    }

    &.operation-finished {
      display: none;
    }
  }

  .progress-fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;

    background-color: $color-blue;
  }

  .step {
    font-size: 12px;
    color: rgba(0, 0, 0, .25);
    margin-bottom: 16px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &.active {
      color: #000;
      font-weight: bold;
    }
  }
}
